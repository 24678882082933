<template>
  <ul class="kt-menu__nav">
    <template v-for="(menu, i) in menuItems">
      <!-- {{ roles + "|" + menu.roles }}
      {{ menu.roles.some((r) => roles.indexOf(r) >= 0) }} -->
      <KTMenuItem
        v-if="!menu.section && menu.roles.some((r) => roles.indexOf(r) >= 0)"
        :menu="menu"
        :key="i"
      ></KTMenuItem>
      <KTMenuSection
        v-if="menu.section && menu.roles.some((r) => roles.indexOf(r) >= 0)"
        :menu="menu"
        :key="i"
      ></KTMenuSection>
    </template>
  </ul>
</template>

<script>
import KTMenuItem from "@/views/theme/aside/MenuItem.vue";
import KTMenuSection from "@/views/theme/aside/MenuSection.vue";
import menuConfig from "@/common/config/menu.config.json";
import { mapGetters } from "vuex";

export default {
  name: "KTAsideMenu",
  components: {
    KTMenuItem,
    KTMenuSection,
  },
  computed: {
    ...mapGetters({
      roles: "roles",
    }),
    menuItems: () => {
      return menuConfig.aside.items;
    },
  },
};
</script>
